<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="下单日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="pickerOptions"
            @change="onSearch"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="配送日期" prop="distr">
          <el-date-picker
            v-model="table.params.distr"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :picker-options="selpickerOptions"
            :clearable="false"
            id="date" @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="city" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.city"
            :options="areaArr"
            :props="{ checkStrictly: false,emitPath:false, value:'id', label:'name'}"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" clearable @focus="getMerchantSel" @change="changeMerchant">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch">
            <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh-right" @click="reset"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <div class="btnAdd" style="display:flex;align-items: center;">
        <el-button :size="subUnitSize" type="primary" plain @click="exportDataAll('/admin/stat/orderMainExport')" icon="el-icon-download" >导出</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <!-- 毛利 = 销售金额 - 采购金额 -->
      <template v-slot:profit="{row}">
        <span :class="(Number(row.total_amount) - Number(row.delivery_money)).toFixed(2) < 0 ? 'redColor' : ''">{{(Number(row.total_amount) - Number(row.delivery_money)).toFixed(2)}}</span>
      </template>
      <template v-slot:date2="{row}">
        <span>{{row.date}}</span>
      </template>
      <template v-slot:cation="{row}">
        <el-button type="text" @click="showDetail(row)">查看</el-button>
        <el-button type="text" @click="exportData('/admin/stat/orderExport',row)">导出</el-button>
      </template>
    </VTable>

    <!-- 详情 -->
    <detail ref="detail"></detail>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'

export default {
  name: '',
  components: {
    Detail,
    VTable
  },
  mixins: [mixinTable],
  data() {
    return {
      roleType: getStorage('role_type'), // 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      multipleSelection: [],
      field: [
        { name: "date", label: "配送日期", hidden: false },
        { name: "order_count", label: "订单笔数", hidden: false},
        { name: "total_quantity", label: "下单数量", hidden: false},
        { name: "total_amount", label: "销售金额/元", hidden: false},
        { name: "sorter_num", label: "分拣数量", hidden: false},
        { name: "delivery_money", label: "采购金额/元", hidden: false},
        { name: "profit", label: "毛利/元", hidden: false},
        { name: "cation", label: "操作", width:'120', hidden: false},
      ],
      table: {
        loading: false,
        params: {
          date: [],
          start_time: '',
          end_time: '',
          distr: [],
          distr_start: '',
          distr_end: '',
          city: '',
          merchant_id: '',
          address_id: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      areaArr: [],
      minDate:'',
      maxDate:'',
      pickerMinDate: '',
      curveTime : '',
      isRestart : '',
      selpickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.isRestart = false
          this.pickerMinDate = minDate.getTime()
          if (maxDate) {
            this.pickerMinDate = ''
          }
        },
        disabledDate: (time) => { //查询时间跨度为31天
          if (this.curveTime && this.isRestart) {
            return // 存在选中的日期且没有重新选择日期 不做禁用处理
          }
          if (this.pickerMinDate !== '') {
            const one = 31 * 24 * 3600 * 1000
            const minTime = this.pickerMinDate - one
            const maxTime = this.pickerMinDate + one
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      },
      merchantArr: [],
      storeArr: [],
    }
  },
  created() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    this.table.params.distr[0] = this.$moment(start).format("YYYY-MM-DD");
    this.table.params.distr[1] = this.$moment(end).format("YYYY-MM-DD");
    this.getTable();
    this.getAddress();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      let _date = this.table.params.date;
      if(!!_date && _date.length > 0) {
        _params.start_time = _date[0]
        _params.end_time = _date[1]
      } else {
        _params.start_time = '';
        _params.end_time = '';
      }
      let _distr = this.table.params.distr;
      if(_distr &&  _distr.length > 0) {
        _params.distr_start = _distr[0]
        _params.distr_end = _distr[1]
      } else {
        _params.distr_start = '';
        _params.distr_end = '';
      }
      delete _params.date
      delete _params.distr
      this.table.loading = true;
      this.$http.get('/admin/stat/order', {params:_params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 系统内门店的地区
    getAddress() {
      this.$http.get('/admin/stat/address', {params:{}}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    showDetail(row) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      let _row = {
        ...row,
        address_id: this.table.params.address_id, // 食堂查询条件
        merchant_id: this.table.params.merchant_id, // 客户名称
      }
      dom.getDetail(_row)
      dom = null;
    },
    exportFun(data) {
      let obj = data.obj;
      let url = data.url;
      let str = ""
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
    // 导出
    exportData(url,row) {
      let obj = {
        date: row.date,
        merchant_id: this.table.params.merchant_id,
        address_id: this.table.params.address_id,
        city: this.table.params.city,
      }
      let data = {
        url: url,
        obj: obj,
      }
      this.exportFun(data)
    },
    exportDataAll(url) {
      let obj = { ...this.table.params }
      let _date = this.table.params.date;
      if(!!_date && _date.length > 0) {
        obj.start_time = _date[0]
        obj.end_time = _date[1]
      } else {
        obj.start_time = '';
        obj.end_time = '';
      }
      let _distr = this.table.params.distr;
      if(_distr &&  _distr.length > 0) {
        obj.distr_start = _distr[0]
        obj.distr_end = _distr[1]
      } else {
        obj.distr_start = '';
        obj.distr_end = '';
      }
      delete obj.date
      delete obj.distr

      // 没有勾选数据默认是全部导出，勾选了数据就是批量导出
      if(this.multipleSelection.length > 0) {
        obj.ids = this.multipleSelection.map(v => { return v.date}).join("|")
      }
      let data = {
        url: url,
        obj: obj,
      }
      this.exportFun(data)
    }
    
  }
}
</script>
